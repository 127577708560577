<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog
      as="div"
      :initial-focus="focusRef"
      class="relative z-30"
      @close="autoClose"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm"
        ></div>
      </TransitionChild>

      <TransitionChild as="template" :="transition">
        <DialogPanel
          :class="[
            'fixed flex h-screen transform flex-col overflow-auto bg-white shadow-xl transition-all',
            position
          ]"
          :="$attrs"
        >
          <input ref="focusRef" type="hidden" />
          <Tippy
            is="button"
            class="Btn--icon !absolute right-4 top-3 z-10"
            type="button"
            content="Close"
            placement="bottom"
            @click="emit('close')"
          >
            <Icon name="x" class="h-4 w-4" />
          </Tippy>
          <slot></slot>
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel
} from '@headlessui/vue'

// defines
defineOptions({ inheritAttrs: false })
const props = defineProps<{
  open: boolean
  staticBackDrop?: boolean
  slideFrom?: 'right' | 'left' | 'bottom'
}>()
const emit = defineEmits(['open', 'close', 'confirm'])

// data
const focusRef = ref<HTMLElement | null>(null)
provide('slideModalInitialFocus', focusRef)

const transitionMap = {
  right: {
    enterFrom: 'translate-x-full',
    enterTo: 'translate-x-0',
    leaveFrom: 'translate-x-0',
    leaveTo: 'translate-x-full'
  },
  left: {
    enterFrom: '-translate-x-full',
    enterTo: 'translate-x-0',
    leaveFrom: 'translate-x-0',
    leaveTo: '-translate-x-full'
  },
  bottom: {
    enterFrom: 'translate-y-full',
    enterTo: 'translate-y-0',
    leaveFrom: 'translate-y-0',
    leaveTo: 'translate-y-full'
  }
}
const transition = {
  enter: 'duration-500 ease-out',
  leave: 'duration-300 ease-in',
  ...transitionMap[props.slideFrom || 'right']
}
const positionMap = {
  right: 'top-0 right-0 min-w-[90vw] sm:min-w-[34.375rem] h-screen px-6',
  left: 'top-0 left-0 sm:w-[72vw] sm:max-w-[34.375rem] h-screen',
  bottom:
    'bottom-0 left-0 lg:max-h-[calc(100vh-1rem)] h-screen w-screen lg:rounded-t-3xl'
}
const position = positionMap[props.slideFrom || 'right']

const themeOptions = computed(() => props.open)

useModalThemeColor(themeOptions)

// methods
const autoClose = () => {
  if (props.staticBackDrop === true) return
  emit('close')
}
</script>
